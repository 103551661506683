import React from "react"
import AppBarNew from "../../components/Appbar"
import Footer from "../../components/Footer"
import "./Help.css"
import Football from "../../../images/Football/football.svg"
import Backdrop from "../../../images/Backdrop/Page8.png"
import Btn1 from "../../../images/BTN1/btn1.svg"
import Btn2 from "../../../images/BTN2/Btn2.svg"
import Head from "../../components/Head"

function Help() {
  return (
    <>
      <Head pageTitle="Help" />
      <AppBarNew />
      <div>
        <img src={Backdrop} className="Backdrop" alt="" />
      </div>
      <div className="backdroptxt1">Help</div>
      <div className="backdroptxt">Contact support</div>
      <div>
        <img src={Football} className="Football" alt="" />
      </div>
      <div className="left">
        <h5 className="h5">Opening hours</h5>
        <span className="span">
          Weekdays: 09-17
          <br />
        </span>
        <span className="span">
          Saturday: 11-16
          <br />
        </span>
        <span className="span">Holiday & Sunday: Closed</span>
        <br />
        <hr className="hr" />
        <div>
          <button className="btn1">
            <img src={Btn1} className="Btn1" alt="" />
            <span className="btntext1">+46 7 25 02 25 05</span>{" "}
          </button>
        </div>
        <div>
          <button className="btn2">
            <img src={Btn2} className="Btn2" alt="" />
            <span className="btntext2">support@racqy.com</span>
          </button>
        </div>
      </div>
      <br />

      <Footer />
    </>
  )
}

export default Help
